<template>
  <div class="home">
    <section id="slideshow" class="slideshow">
      <video muted autoplay loop playsinline>
        <source src="/assets/video/output.mp4" />
        <source src="/assets/video/output.mp4" onerror="fallback(parentNode)" />
        <img src="/assets/img/patient_stories/EmmaSpijkers_m.jpg" alt="" />
      </video>
      <div class="info-text masterplan">
        <a href="#location">
          <h1 class="title">
            <span class="title-secondary">Jouw </span>
            <span class="title-primary">herstel</span><br />
            <span class="title-secondary">onze </span>
            <span class="title-primary">zorg</span>
          </h1>
        </a>
        <div class="contact-items">
          <a class="whatsapp" href="https://wa.me/31621436994" target="_blank">
            <h2>
              <span class="mdi mdi-whatsapp"></span>
            </h2>
          </a>
          <a
            class="email"
            href="mailto:info@faamfysiotherapie.nl"
            target="_blank"
          >
            <h2>
              <span class="mdi mdi-email"></span>
            </h2>
          </a>
          <a class="phone" href="#contact-form">
            <h2>
              <span class="mdi mdi-phone"></span>
            </h2>
          </a>
          <a class="make-appointment" href="#contact-form">Maak een afspraak</a>
        </div>
      </div>
    </section>
    <section class="treatments">
      <treatments />
    </section>
    <section id="team" class="team">
      <h1 class="masterplan title">Ons team</h1>
      <div class="persons">
        <a
          :href="'/faam/team#' + person.name.toLowerCase()"
          class="person"
          v-for="person in team"
          :key="person.key"
        >
          <img :src="person.image" :alt="person.name" />
          <div class="info">
            <h3 class="name">{{ person.name }}</h3>
            <p class="description">{{ person.description }}</p>
          </div>
        </a>
      </div>
    </section>
    <section id="welcome" class="welcome">
      <div class="container">
        <div class="text">
          <h1 class="masterplan">Welkom bij Faam</h1>
          <p>
            Bij Faam Fysiotherapie streven we ernaar om de gezondheid en het
            welzijn van onze patiënten te bevorderen door hoogwaardige
            fysiotherapeutische zorg te bieden. Onze missie is om iedereen die
            onze praktijk bezoekt de best mogelijke behandeling te geven met
            focus op herstel, preventie en het verbeteren van de kwaliteit van
            leven. Daarnaast willen we een toonaangevende en toegankelijke
            fysiotherapiepraktijk zijn in de Bitswijk en Uden en omgeving.
          </p>
        </div>
        <div class="image">
          <img
            src="/assets/img/praktijk/Uden/Oefenzaal-2_m.jpg"
            alt="Oefenzaal"
          />
        </div>
      </div>
    </section>
    <section id="muzerijk" class="muzerijk">
      <div class="container">
        <div class="text">
          <h1 class="masterplan">Ontmoetingscentrum Muzerijk</h1>
          <p>
            Wij zijn als praktijk gevestigd in het MuzeRijk in Uden op de eerste
            verdieping. Als u de trap of lift neemt naar de eerste verdieping
            vindt u de deur aan uw rechterzijde. Als u doorloopt komt u in de
            wachtruimte uit.
          </p>
          <p>
            De bedoeling van MuzeRijk is een ontmoetingsplek bieden waar
            iedereen welkom is ongeacht leeftijd, achtergrond, cultuur of talent
            en op zijn eigen manier kan meedenken en meedoen met alles wat de
            bewoners en MuzeRijk te bieden hebben. Ons MuzeRijk is de plek waar:
          </p>
          <ul>
            <li>We elkaar op een laagdrempelige manier ontmoeten;</li>
            <li>
              Ieder zichzelf kan ontplooien en elkaar en de buurt helpen te
              ontwikkelen;
            </li>
            <li>
              Om te ontspannen, te sporten, te leren, te werken en gewoon om er
              te zijn.
            </li>
          </ul>
          <p>
            Onze kernwaarden blijven uitdragen: verbinden, verrijken, beleven.
          </p>
        </div>
        <div class="image" style="aspect-ratio: 560/315">
          <iframe
            src="https://www.youtube.com/embed/JNjpMcUspEM?controls=0"
            title="YouTube video player"
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
    <section id="patient-stories" class="patient-stories">
      <div class="text">
        <h1 class="masterplan">Patientverhalen</h1>
        <p>
          Graag laten we een aantal patiënten van ons aan het woord. In
          onderstaande video's delen zij hun ervaringen met de fysiotherapie en
          het revalidatietraject. Er komen verschillende blessures aan bod. Bij
          Faam Fysiotherapie bepalen we samen met u een passende richting in de
          revalidatie, bieden we intensieve individuele begeleiding en stellen
          we haalbare doelen om bewegen of sporten weer vanzelfsprekend te
          maken.
        </p>
      </div>
      <carousel :items="patientStories" />
    </section>
    <!--    <section id="reviews" class="reviews">-->
    <!--      <h1>Reviews Google en/of Zorgkaart-Nederland</h1>-->
    <!--    </section>-->
    <section id="contact" class="main-contact-info">
      <div class="pre-info">
        <h1 class="masterplan">Contact</h1>
        <p>
          Heeft u vragen of wilt u een afspraak maken? Neem dan contact met ons
          op.
        </p>
        <p>
          Voor het maken van een afspraak kunt u ons telefonisch bereiken op
          0413-783357. Wij zijn telefonisch bereikbaar op maandag t/m vrijdag
          tussen 08:00 en 17:00. Buiten deze tijden kunt u een voicemail
          inspreken, wij bellen u dan zo spoedig mogelijk terug.
        </p>
        <h1 class="masterplan">Route</h1>
        <p>
          Via de hoofdingang van het MuzeRijk loopt u voorbij de receptie
          richting het trappenhuis. Op de eerste verdieping ziet u aan uw
          rechter hand de deur naar de wachtruimte van het Gezondheidscentrum.
          Hier halen wij u op.
        </p>
        <p>
          Indien u slecht ter been bent is er ook een lift aanwezig naar de
          eerste verdieping.
        </p>
      </div>
      <div class="fast-contact">
        <div class="contact-info">
          <h1 class="masterplan">Praktijkgegevens</h1>
          <h3>Locaties:</h3>
          <strong>Uden</strong>
          <p>Gezondheidscentrum MuzeRijk Klarinetstraat 4 5402 BE Uden</p>
          <strong>Wilbertoord</strong>
          <p>Gemeenschapshuis De Wilg Lindersstraat 11 5455 GJ Wilbertoord</p>
          <br />
          <h3>Telefoon:</h3>
          <p>
            <a href="tel:0413783357">0413-783357</a>
          </p>
          <br />
          <h3>Email:</h3>
          <p>
            <a href="mailto:info@faamfysiotherapie.nl"
              >info@faamfysiotherapie.nl</a
            >
          </p>
        </div>
      </div>
    </section>
    <section id="location" class="location">
      <div class="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2474.412092487378!2d5.613870378351703!3d51.670599571851206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c71d50a64df965%3A0x10c2e056666a6f9b!2sFaam%20Fysiotherapie%20Uden!5e0!3m2!1sen!2snl!4v1686588935660!5m2!1sen!2snl"
          width="100%"
          height="50%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2475.061265164528!2d5.769402878351344!3d51.658714671847875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c71b4f86922f19%3A0x869a3ea4941d91dd!2sFaam%20Fysiotherapie%20Wilbertoord!5e0!3m2!1sen!2snl!4v1686589029331!5m2!1sen!2snl"
          width="100%"
          height="50%"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
    <section id="contact-form" class="contact-formulier">
      <h1 class="masterplan">Contact formulier</h1>
      <contact />
    </section>
  </div>
</template>

<script>
import Treatments from "@/components/sections/Treatments";
import Contact from "@/components/Contact";
import Carousel from "@/components/carousel/Carousel.vue";
export default {
  name: "HomeView",
  head: {
    title: "Faam | Welkom",
    meta: [
      {
        name: "description",
        content:
          "Welkom bij Faam Fysiotherapie in Uden. Onze praktijk biedt gespecialiseerde zorg voor al uw lichamelijke klachten. Onze ervaren therapeuten helpen u snel weer op de been. Maak vandaag nog een afspraak!",
      },
    ],
  },
  components: {
    Treatments,
    Contact,
    Carousel,
  },
  data: function () {
    return {
      team: [
        {
          name: "Mario",
          description: "MSc. Manueel therapeut",
          image: "/assets/img/team/Mario_s.png",
        },
        {
          name: "Lars",
          description:
            "MSc. Musculoskeletale revalidatie / MSc. Manuele therapie",
          image: "/assets/img/team/Lars_s.png",
        },
        {
          name: "Jeroen",
          description: "Algemeen fysiotherapeut / Schouder revalidatie",
          image: "/assets/img/team/Jeroen_s.png",
        },
        {
          name: "Marijn",
          description: "MSc. Neurorevalidatie / Neurologisch fysiotherapeut",
          image: "/assets/img/team/Marijn_s.png",
        },
        {
          name: "Heidi",
          description: "Massage therapeut / Administratief medewerker",
          image: "/assets/img/team/Heidi_s.png",
        },
      ],
      patientStories: [
        {
          title: "Emma",
          description: "Kraakbeenschade & voorste kruisband revalidatie knie",
          url: "/patient-stories/#emma",
          image: "/assets/img/patient_stories/EmmaSpijkers_m.jpg",
        },
        {
          title: "Mo",
          description:
            "Valgiserende tibiakop osteotomie (standscorrectie onderbeen)",
          url: "/patient-stories/#mo",
          image: "/assets/img/patient_stories/Mo_m.jpg",
        },
        {
          title: "Imke",
          description: "Olecranon fractuur (elleboogbreuk)",
          url: "/patient-stories/#imke",
          image: "/assets/img/patient_stories/Imke_m.jpg",
        },
        {
          title: "Kevin",
          description: "Voorste kruisband reconstructie (VKBR)",
          url: "/patient-stories/#kevin",
          image: "/assets/img/patient_stories/Kevin_m.jpg",
        },
      ],
      openTimes: [
        {
          day: "Maandag",
          locations: [
            { name: "Uden", start: "09:00", end: "21:30" },
            { name: "Wilbertoord", start: "09:00", end: "13:00" },
          ],
        },
        {
          day: "Dinsdag",
          locations: [{ name: "Uden", start: "09:00", end: "21:30" }],
        },
        {
          day: "Woensdag",
          locations: [{ name: "Uden", start: "09:00", end: "17:00" }],
        },
        {
          day: "Donderdag",
          locations: [
            { name: "Uden", start: "09:00", end: "21:30" },
            { name: "Wilbertoord", start: "09:00", end: "13:00" },
          ],
        },
        {
          day: "Vrijdag",
          locations: [{ name: "Uden", start: "09:00", end: "20:30" }],
        },
      ],
    };
  },
  methods: {
    fallback: function (element) {
      const img = document.createElement("img");
      img.src = "/assets/img/patient_stories/EmmaSpijkers_m.jpg";
      element.appendChild(img);
    },
  },
};
</script>

<style scoped lang="less">
.home {
  margin-top: -140px;
  z-index: -1;
  //background: linear-gradient(
  //  rgba(0, 0, 0, 0),
  //  rgba(0, 0, 0, 0) 200px,
  //  rgba(0, 0, 0, 1),
  //  #4e4d4d,
  //  var(--primary-color),
  //  #ffffff
  //);
  section {
    text-align: center;
    &.slideshow {
      display: flex;
      height: 100vh;
      justify-content: center;

      video {
        object-fit: cover;
        z-index: -1;
        min-width: 100%;
        height: calc(100vh + 248px);
        //height: 100vh;
        filter: brightness(33%);
      }

      .info-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        color: #ffffff;
        position: absolute;
        max-width: 100%;
        text-align: center;
        z-index: 1;

        h1 {
          font-size: 100px;
          font-weight: lighter;
          padding: 0;
          margin: 0;
          //background: -webkit-linear-gradient(#00a199, #ffffff);
          //-webkit-background-clip: text;
          //-webkit-text-fill-color: transparent;
          transition-duration: 0.7s;
          //&:hover {
          //  background: -webkit-linear-gradient(#ffffff, #00a199);
          //  -webkit-background-clip: text;
          //  -webkit-text-fill-color: transparent;
          //}
          .title-primary {
            color: var(--primary-color);
            transition-duration: 0.7s;
          }
          .title-secondary {
            color: #ffffff;
            transition-duration: 0.7s;
          }
          &:hover {
            .title-primary {
              color: #ffffff;
            }
            .title-secondary {
              color: var(--primary-color);
            }
          }
        }
        .contact-items {
          display: grid;
          grid-template:
            "whatsapp email phone"
            "make-appointment make-appointment make-appointment" / 1fr 1fr 1fr;
          width: 100%;
          justify-content: space-around;
          h2 {
            display: flex;
            font-size: 50px;
            color: #fff;
            height: 100px;
            align-items: center;
            justify-content: center;
            transition-duration: 0.7s;
            background-color: rgba(0, 161, 153, 0.1);

            &:hover {
              background-color: var(--primary-color);
            }
          }

          .whatsapp,
          .email,
          .phone {
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
              width: 100px;
              border-radius: 100%;
            }
          }

          .whatsapp {
            grid-area: whatsapp;
          }
          .email {
            grid-area: email;
          }
          .phone {
            grid-area: phone;
          }
          .make-appointment {
            grid-area: make-appointment;
            font-weight: lighter;
            width: 100%;
            font-size: 50px;
            padding: 10px;
            color: #ffffff;
            background-color: rgba(0, 161, 153, 0.1);
            border-radius: 10px;
            transition-duration: 0.7s;
            &:hover {
              color: #ffffff !important;
              background-color: var(--primary-color);
            }
          }
        }
      }
    }
    &.treatments {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: left;
      width: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      color: #ffffff;
      padding: 50px 50px;
    }
    &.team {
      //background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      background: #000000;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        text-align: left;
        color: #ffffff;
      }
      .persons {
        position: relative;
        display: flex;
        gap: 100px;
        justify-content: space-around;
        .person {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          width: 100%;
          max-width: 300px;
          margin: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 25px;
          }

          .info {
            position: absolute;
            bottom: 0;
            padding: 10px;
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            width: 100%;
            overflow: hidden;

            .name {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 10px;
            }

            .description {
              opacity: 0;
              height: 0;
              bottom: 0;
              margin-block-start: 0;
              margin-block-end: 0;
              transition-duration: 0.7s;
            }
          }

          &:hover {
            .info {
              .description {
                opacity: 1;
                height: 3rem;
                margin-block-start: 1em;
                margin-block-end: 1em;
              }
            }
          }
        }
      }
    }
    &.welcome {
      display: flex;
      justify-content: center;
      background: linear-gradient(rgba(0, 0, 0, 1), #4e4d4d);
      color: #ffffff;
      text-align: left;
      width: 100%;
      .container {
        display: flex;
        gap: 50px;
        .text {
          flex-direction: column;
          justify-content: space-around;
        }
        .image {
          display: flex;
          max-width: 100%;
          img {
            border-radius: 25px;
            max-width: 100%;
            max-height: 1000px;
          }
        }
      }
    }
    &.muzerijk {
      display: flex;
      color: #ffffff;
      background: #4e4d4d;
      text-align: left;
      width: 100%;
      .container {
        display: flex;
        gap: 50px;
        .text {
          flex-direction: column;
          justify-content: space-around;
        }
        .image {
          display: flex;
          align-items: center;
          max-width: 100%;
          iframe {
            border-radius: 25px;
            width: 560px;
            height: 315px;
          }
        }
      }
    }
    &.patient-stories {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: left;
      width: 100%;
      //max-height: 100vh;
      //background-color: rgba(0, 161, 153, 1);
      background: #4e4d4d;
      //background-color: #000;
      color: #ffffff;
    }
    &.main-contact-info {
      display: flex;
      gap: 100px;
      color: #ffffff;
      background: linear-gradient(#4e4d4d, var(--primary-color));
      text-align: left;

      .pre-info {
        width: calc(50% - 50px);
      }

      .fast-contact {
        width: 50%;
        .contact-info {
          a {
            color: #ffffff;
            &:hover {
              color: #4e4d4d !important;
            }
          }
        }
      }
    }
    &.location {
      display: flex;
      //background-color: #00a199;
      color: #ffffff;
      background: var(--primary-color);
      text-align: left;
      width: 100%;
      .open-times {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .open-time {
          padding: 0 10px;
          .day {
            margin-top: 0;
          }
        }
      }
      .map {
        display: flex;
        justify-content: space-around;
        gap: 50px;
        width: 100%;
        height: 500px;
        iframe {
          border-radius: 25px;
          width: 100%;
          height: 100%;
        }
      }
      .info {
        width: 100%;
        .extra-info {
          display: flex;
          .openings-tijden,
          .address,
          .contact-info {
            width: calc(100% / 3);
            p {
              a {
                color: white;

                &:hover {
                  color: #4e4d4d !important;
                }
              }
            }
          }
        }
      }
    }
    &.contact-formulier {
      text-align: left;
      //background-color: #ffffff;
    }
    &.reviews {
      color: var(--primary-color);
    }
    h1 {
      font-size: xxx-large;
    }
  }
}

@media screen and (max-width: 961px) {
  .home {
    section {
      &.team {
        .persons {
          gap: 50px;
          flex-wrap: wrap;
          .person {
            width: calc((100% - 100px) / 2);
            .info {
              .description {
                opacity: 1;
                height: unset;
              }
            }
          }
        }
      }
      &.welcome {
        .container {
          flex-direction: column;
          .image {
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &.muzerijk {
        .container {
          flex-direction: column;
          .image {
            iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home {
    margin-top: -145px;
    section {
      &.slideshow {
        .info-text {
          h1 {
            font-size: 75px;
          }
        }
      }
      &.treatments {
        padding: 30px 50px;
      }
      &.team {
        .persons {
          .person {
            width: 100%;
          }
        }
      }
      &.main-contact-info {
        flex-direction: column;
        gap: 0;
        .pre-info {
          width: 100%;
        }
      }
      &.location {
        flex-direction: column;
        .map {
          flex-direction: column;
          gap: 15px;
          height: 100%;
        }
      }
      h1 {
        font-size: xx-large;
      }
    }
  }
}
</style>

<template>
  <form
    v-if="mailSend == false"
    class="contact-form"
    action=""
    method="POST"
    @submit="onSubmit"
  >
    <div class="name">
      <text-input
        type="text"
        class="first-name"
        placeholder="Voornaam*"
        post_name="name"
        autocomplete="given-name"
        :required="true"
      ></text-input>
      <text-input
        type="text"
        class="last-name"
        post_name="lastname"
        placeholder="Achternaam*"
        autocomplete="family-name"
        :required="true"
      />
    </div>
    <div class="birthday">
      <text-input
        type="date"
        placeholder="Geboortedatum*"
        post_name="birthday"
        autocomplete="bday"
        :required="true"
      ></text-input>
    </div>
    <div class="phone">
      <text-input
        type="text"
        placeholder="Telefoon*"
        post_name="phone"
        autocomplete="tel"
        :required="true"
      ></text-input>
    </div>
    <div class="email">
      <text-input
        type="email"
        placeholder="E-mailadres*"
        post_name="email"
        autocomplete="email"
        :required="true"
      ></text-input>
    </div>
    <div class="message">
      <text-input
        type="textarea"
        placeholder="Korte omschrijving klacht(en)"
        post_name="message"
      />
    </div>
    <div>
      <input type="checkbox" name="privacy" id="privacy" :required="true" />
      <label for="privacy">
        Ik ga akkoord met de
        <a @click="showPrivacyStatement = true">Privacy Voorwaarden</a>
      </label>
    </div>
    <div>
      <input type="submit" class="btn-primary" name="submit" value="Verstuur" />
    </div>
  </form>
  <div v-else>
    <p>
      Bedankt voor het invullen van het contact formulier, we nemen zo spoedig
      mogelijk contact met u op.
    </p>
    <button class="btn-primary" @click="mailSend = false">
      Vul opnieuw in
    </button>
  </div>
  <modal :show="showPrivacyStatement" @close="showPrivacyStatement = false">
    <iframe
      src="/assets/Privacy-Statement-Faam-Fysiotherapie.pdf"
      frameborder="0"
      style="height: 100%; width: 100%"
    ></iframe>
  </modal>
</template>

<script>
import TextInput from "@/components/input/text-input.vue";
import Modal from "@/components/modal/Modal.vue";

export default {
  name: "Contact",
  components: {
    Modal,
    TextInput,
  },
  data() {
    return {
      showPrivacyStatement: false,
      mailSend: false,
    };
  },
  methods: {
    onSubmit(e) {
      console.log(e);
      e.preventDefault();
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha
          .execute("6LebHbcpAAAAAFIuKz4qCLrQjSXOq8ehp4ooqKzz", {
            action: "submit",
          })
          .then(async (token) => {
            if (token === null) {
              console.error("No token provided");
              return;
            }
            console.log("Submit form: " + token);

            let name = document.getElementsByName("name")[0];
            let lastname = document.getElementsByName("lastname")[0];
            let birthday = document.getElementsByName("birthday")[0];
            let phone = document.getElementsByName("phone")[0];
            let mail = document.getElementsByName("email")[0];
            let message = document.getElementsByName("message")[0];

            let data = {
              name: name.value,
              lastname: lastname.value,
              birthday: birthday.value,
              phone: phone.value,
              email: mail.value,
              message: message.value,
              token: token,
            };

            const response = await fetch(`/index.php`, {
              method: "POST",
              mode: "no-cors",
              cache: "no-cache",
              credentials: "same-origin",
              headers: {
                "Content-Type": "application/json",
              },
              redirect: "follow",
              referrerPolicy: "no-referrer",
              body: JSON.stringify(data),
            });

            let json = await response.json();

            if (json.mail_send) {
              this.mailSend = true;
              message.value = "";
            }
          });
      });
    },
  },
};
</script>

<style scoped lang="less">
.contact-form {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    width: 100%;
    align-items: flex-start;
    &.name {
      display: flex;
      justify-content: space-between;
      .input-effect {
        width: calc(50% - 10px);
      }
    }
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-color);
    &:hover {
      color: var(--secondary-color);
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-form {
    div {
      &.name {
        flex-direction: column;

        .input-effect {
          width: 100%;
        }
        .btn-primary {
          width: 100%;
        }
      }
    }
  }
}
</style>
